<template>
    <div class="columns is-centered">
        <div class="column is-full pt-6">
            <span class="meb-title" v-html="$t('SEC_4_TITLE')"></span>
        </div>
    </div>
    <div class="columns is-centered pb-4">
        <div class="column mt-6">
            <div class="left-aligned">
                <p class="meb-subtitle mb-1">{{ $t('SEC_4_SUBTITLE_1') }}</p>
                <p class="meb-title meb-black">{{ $t('SEC_4_SUBTITLE_2') }}</p>
                <div class="mt-6 mb-5">
                    <div class="center-container-vertical-flex">
                        <figure class="image">
                            <img src="@/assets/images/Raggruppa 18003.png">
                        </figure>
                        <p class="meb-subtitle">{{ $t('SEC_4_STEP_1') }}</p>
                    </div>
                    <div class="center-container-vertical-flex mt-6 mb-6">
                        <figure class="image">
                            <img src="@/assets/images/Raggruppa 18004.png">
                        </figure>
                        <p class="meb-subtitle">{{ $t('SEC_4_STEP_2') }}</p>
                    </div>
                    <div class="center-container-vertical-flex">
                        <figure class="image">
                            <img src="@/assets/images/Raggruppa 18005.png">
                        </figure>
                        <p class="meb-subtitle" v-html="$t('SEC_4_STEP_3')"></p>
                    </div>
                </div>
                <div class="center-aligned mt-6">
                    <p class="meb-txt">{{ $t('SEC_4_STEP_4') }}</p>
                </div>
            </div>
        </div>
        <div class="column mt-6">
            <div class="form-container">
                <div class="body-container-90">
                    <div class="center-aligned pt-5">
                        <span class="meb-form-txt" v-html="$t('SEC_4_FORM_TITLE')"></span>
                    </div>
                    <div class="pt-6"></div>
                    <div class="left-aligned">
                        <div>
                            <p class="meb-p mb-4" for="fname">{{ $t('FORM_NAME_TXT') }}</p>
                            <input 
                                id="fname" 
                                name="fname" 
                                class="input is-rounded" 
                                type="text" 
                                :placeholder="$t('FORM_PLACEHOLDER_NAME') " />
                        </div>
                        <div class="mt-6 mb-6">
                            <p class="meb-p mb-4" for="fasset">{{ $t('FORM_ASSSET_TXT') }}</p>
                            <input 
                                id="fasset" 
                                name="fasset" 
                                class="input is-rounded" 
                                type="text" 
                                :placeholder="$t('FORM_PLACEHOLDER_ASSET')"/>
                        </div>
                        <div>
                            <p class="meb-p mb-4" for="freq">{{ $t('FORM_REQ_TXT') }}</p>
                            <textarea 
                                id="freq"
                                name="freq" 
                                class="textarea" 
                                :placeholder="$t('FORM_PLACEHOLDER_REQ')"
                                style="border-radius: 19px">
                            </textarea>
                        </div>
                    </div>
                    <button class="button is-rounded btn-orange mt-6 mb-6" disabled>
                        <span class="btn-txt">{{ $t('FORM_SUBMIT_TXT') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>

.meb-title{
    color: #F15A24;
}

.meb-black{
color: black;
}

.meb-subtitle{
    color: black;
    font-family: 'Roboto-medium';
    font-size: 1.1rem;
}

.meb-subtitle-orange{
    color: #F15A24;
    font-family: 'Roboto-medium';
    font-size: 1.1rem;
    letter-spacing: 0.5px;
}

.image {
    min-width: 20%;
    display: block;
    position: relative;
}

.image img{
    all: unset;
}

.meb-txt{
    font-family: 'Roboto-black';
    font-size: 1.7rem;
    letter-spacing: 0.5px;
    color: #F15A24;

}

@media only screen and (max-width: 425px) {
.image {
        margin-right: 2rem;
    }

    .image img {
        display: block;
        height: auto;
        width: 100%;
    }

    .meb-txt{
        font-size: 1.2rem;
    }

}
</style>

<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps({
  image: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});
</script>

<template>
    <div v-if="props.image!=''" class="carousel-inner-container mt-6 mr-6 pb-6">
      <figure class="image">
          <img :src="require(`@/assets/images/${props.image}`)">
      </figure>
        <div class="center-aligned">
          <p v-if="props.image.startsWith('carousel-bk')" class="mt-4 meb-text-carousel-white">{{$t('ITEM_'+ props.name )}}</p>
          <p v-else class="meb-text-carousel-yellow">{{$t('ITEM_'+ props.name )}}</p>
        </div>
    </div>
    <div v-else class="carousel-inner-container mt-6 mr-6 pb-6">
        <div class="center-aligned pt-6">
            <span  class="meb-txt-gradient">{{$t('ITEM_'+ props.name )}}</span >
        </div>
    </div>
</template>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/templates/main.css'
import 'bulma'
import { createI18n } from 'vue-i18n';
import loadLocaleMessages from './i18n/i18n'; 

const usersLanguage = store.state.availibleLocales.includes(window.navigator.language) ? window.navigator.language: 'it' ;

export {usersLanguage};

export const i18n = createI18n({
    legacy: false,
    locale: usersLanguage,
    messages: loadLocaleMessages(),
  });

createApp(App)
.use(store)
.use(router)
.use(i18n)
.mount('#app')

<template>
    <div class="columns is-vcentered pt-6">
        <div class="column is-5">
            <div class="left-aligned pt-6">
                <span class="pt-6 meb-title">{{ $t('WELCOME_TITLE') }}</span>
                <figure class="image mt-5 mb-6">
                    <img src="@/assets/images/azienda/magnaebasta.svg" >
                </figure>            
                <p class="meb-subtitle mb-3" v-html="$t('WELCOME_SUBTITLE')"></p>   
                <button class="button is-rounded btn-orange is-medium is-responsive mt-6">
                    <span class="meb-btn-txt">{{ $t('TXT_FIND_OUT_MORE') }}</span>
                </button>
            </div>
            
        </div>
        <div class="column is-7">
            <figure class="image">
                <img src="@/assets/images/Mockup.png" >
            </figure>
        </div>
    </div>
</template>

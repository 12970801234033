<template>
    <div class="meb-margin">
        <div class="center-aligned">
            <p class="meb-title-orange">{{ $t('MERCHANT_TITLE_1') }}</p>
        </div>
        <figure class="pt-5 pb-5">
            <img class="meb-img" src="@/assets/images/Raggruppa 18013.png" >
        </figure>
        <div class="center-aligned">
            <p class="meb-text-p-med">{{ $t('MERCHANT_DESC_1') }}</p>
        </div>
    </div>


    <div class="meb-margin">
        <div class="center-aligned">
            <p class="meb-title-orange">{{ $t('MERCHANT_TITLE_2') }}</p>
        </div>
        <figure class="pt-5 pb-5">
            <img class="meb-img" src="@/assets/images/Raggruppa 18014.png" >
        </figure>
        <div class="center-aligned">
            <p class="meb-text-p-med">{{ $t('MERCHANT_DESC_2') }}</p>
        </div>
    </div>

    <div class="meb-margin">
        <div class="center-aligned">
            <p class="meb-title-orange">{{ $t('MERCHANT_TITLE_3') }}</p>
        </div>
        <figure class="pt-5 pb-5">
            <img class="meb-img" src="@/assets/images/Raggruppa 18015.png" >
        </figure>
        <div class="center-aligned">
            <p class="meb-text-p-med">{{ $t('MERCHANT_DESC_3') }}</p>
        </div>
    </div>
</template>

import { createStore } from 'vuex'

export default createStore({
  state: {
    availibleLocales : ['it', 'en'],
    locale: ''
  },

  getters: {
    getLocale: (state)=> (lang: string) => {
      if (state.availibleLocales.includes(lang)) {
        return lang;
      } else {
        return state.locale;
      }
    },
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },
  },
  actions: {
  },
  modules: {
  }
})

<script setup lang="ts">
import HomeSectionClienteComponent from '@/components/HomeSectionClienteComponent.vue'
import HomeSectionEsercenteComponent from '@/components/HomeSectionEsercenteComponent.vue'
import { ref } from 'vue'

const buttonSel = ref (1)
function changeComp (type:number) {
  buttonSel.value = type
}
</script>

<template>
    <div class="columns is-centered">
        <div class="column is-full pt-6">
            <p class="meb-title mb-3">{{ $t('SEC_2_TITLE') }}</p>
            <p class="meb-subtitle">{{ $t('SEC_2_SUBTITLE') }}</p>
        </div>
    </div>  
    
    <div class="center-container">
        <div class="mt-4 es-cli-container p-1">
            <div class="buttons">
                <button class="button is-rounded" :class=" 1 === buttonSel ? 'is-primary' : 'is-white' " @click="changeComp(1)">
                    <span v-if="1===buttonSel" class="meb-btn-txt">{{ $t('MERCHANT_TXT') }}</span>
                    <span v-else class="meb-btn-txt-black">{{ $t('MERCHANT_TXT') }}</span>
                </button>
                <button class="button is-rounded" :class=" 2 === buttonSel ? 'is-primary' : 'is-white' " @click="changeComp(2)">
                    <span v-if="2===buttonSel" class="meb-btn-txt">{{ $t('CUSTOMER_TXT') }}</span>
                    <span v-else class="meb-btn-txt-black">{{ $t('CUSTOMER_TXT') }}</span>
                </button>
            </div>
        </div>
    </div>
    <div v-if="1===buttonSel" class="center-container carousel-container is-justify-content-center mt-6" style="overflow: unset;">
        <HomeSectionEsercenteComponent/>
    </div>
    <div v-if="2===buttonSel" class="center-container carousel-container is-justify-content-center mt-6" style="overflow: unset;">
        <HomeSectionClienteComponent/>
    </div>
</template>

<style scoped>
.is-primary{
    background: #F15A24;
}

.meb-subtitle{
    color: #434343;
}
.meb-title{
    color: black;
}
</style>
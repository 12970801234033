<script setup lang="ts">
</script>

<template>
    <div class="body-container-bk">
        <div class="body-container-90">
            <div class="columns is-vcentered pt-3">
                <div class="column">
                    <div class="footl-aligned">
                        <div class="column  p-0 meb-txt-align">
                            <p class="meb-p mb-2">{{ $t('PIVA_TXT') }} 04561360985</p>
                            <p class="meb-p mb-2">{{ $t('ADDRESS_TXT') }}</p>
                            <p class="meb-p mb-2">25124, Brescia (BS)</p>
                        </div>
                    </div>
                </div>
                <div class="column m-0 p-0">
                    <div class="center-aligned">
                        <img class="image-f-img" src="@/assets/images/azienda/Raggruppa 17034.gif" style="height: 120px;">
                    </div>
                </div>
                <div class="column m-0 p-3">
                    <div  style="display: ruby-text;">
                        <img class="image-fsocial-img" src="@/assets/images/social/facebook.png">
                        <div class="pr-3 pl-3"></div>
                        <img class="image-fsocial-img" src="@/assets/images/social/instagram.png">
                        <div class="pr-3 pl-3"></div>
                        <img class="image-fsocial-img" src="@/assets/images/social/linkedin.png">
                    </div>
                </div>
            </div>
            <div class="columns is-mobile m-0 p-0">
                <div class="column pt-0 pl-0 has-text-center">
                    <span class="meb-p">{{ $t('COPYRIGHT_TXT') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.meb-txt-align{
    text-align: left !important;
}
.meb-p{
    font-size: 0.8rem;
}

@media only screen and (max-width: 768px) {
    .meb-txt-align{
    text-align: center !important;
    }
}
</style>
<script setup lang="ts">
import { ref } from "vue";
import { defineEmits } from 'vue'
import LanguageComponent from "./LanguageComponent.vue";
const showNav = ref(false);

 const emit = defineEmits<{
   (event: 'scrollToSection', section:number): void;
 }>()
 function scrollToSection (section:number) {
   emit('scrollToSection', section)
 }

</script>

<template>
<nav class="navbar is-fixed-top meb-nav-bkg" role="navigation" aria-label="main navigation ml-4 mr-4">
  <div class="navbar-brand mr-5 ml-5">
    <img :src="require(`@/assets/images/azienda/logo-meb.png`)"/>
    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu meb-nav-bkg" :class="{ 'is-active': showNav }">
    <div class="navbar-start">
      <a class="navbar-item meb-menu-txt" @click="scrollToSection(1)"> {{ $t('NAV_TARGET') }}</a>
      <a class="navbar-item meb-menu-txt" @click="scrollToSection(2)"> {{ $t('NAV_DOES IT WORK') }}</a>
      <a class="navbar-item meb-menu-txt" @click="scrollToSection(3)"> {{ $t('NAV_SERVICES') }}</a>
      <a class="navbar-item meb-menu-txt" @click="scrollToSection(4)"> {{ $t('NAV_CONTACTS') }}</a>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <hr class="mt-3 mb-3">
        <LanguageComponent />
        <hr class="mt-3 mb-3">
        <div class="buttons is-justify-content-center">
          <a class="button is-info">{{ $t('NAV_SIGN') }}</a>
          <a class="button is-primary"> {{ $t('NAV_LOG') }}</a>
        </div>
      </div>
    </div>
  </div>
</nav>
</template>

<style lang="css" scoped>

.is-info{
  border: 2px solid #FFFF3A;
  border-radius: 25px;
  text-align: right;
  letter-spacing: 0px;
  color: #FFFF3A;
  opacity: 1;
  background: #000000 0% 0% no-repeat padding-box;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 13px;
}

.is-primary{
  border: 2px solid #F15A24;
    border-radius: 25px;
    text-align: right;
    letter-spacing: 0px;
    color: #F15A24;
    opacity: 1;
    background: #000000 0% 0% no-repeat padding-box;
    min-width: 120px;
    text-transform: uppercase;    
    font-size: 13px;
}
</style>





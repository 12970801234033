<script setup lang="ts">
import { onMounted } from 'vue'
import { useScrollToTop } from '@/composables/useUtils'
import HomeSection0Component from '@/components/HomeSection0Component.vue'
import HomeSection1Component from '@/components/HomeSection1Component.vue'
import HomeSection2Component from '@/components/HomeSection2Component.vue'
import HomeSection3Component from '@/components/HomeSection3Component.vue'
import HomeSection4Component from '@/components/HomeSection4Component.vue'

onMounted(() => {
  useScrollToTop()
})
</script>

<template>
  <div class="body-container-bk">
    <div class="body-container-90">
      <section id="section0"><HomeSection0Component /></section>
      <hr>
      <section id="section1"><HomeSection1Component /></section>
    </div>
  </div>
  <div class="body-container-gr">
    <div class="body-container-90">
      <section id="section2" class="s-container"><HomeSection2Component /></section>
    </div>
  </div>
  <div class="body-container-bk">
    <div class="body-container-90">
      <section id="section3" class="s-container"><HomeSection3Component /></section>
    </div>
  </div>
  <div class="body-container-gr">
    <div class="body-container-90">
      <section id="section4" class="s-container"><HomeSection4Component /></section>
    </div>
  </div>
</template>

export function useScrollToTop (): void {
  window.scrollTo(0, 0)
}
export function useScrollToSection (section:number) {
  const refName = `section${section}`
  const sectionElement = document.getElementById(refName)
  if (sectionElement) {
    sectionElement.scrollIntoView({ behavior: 'smooth' })
  }
}
export function useGetElementHeightById (id: string): number {
  const element = document.getElementById(id)
  if (element) {
    return element.clientHeight
  }
  return 0
}
